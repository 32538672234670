@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html {
  font-size: 18px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

body {
  margin: 0;
  // Roboto
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  // @apply bg-primary;
  background: linear-gradient(180deg, #1a1a1a 0%, #000000 100%);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 3rem;
  margin-bottom: 16px;
}

h2 {
  // @apply text-tertiary;
  font-size: 2rem;
  margin-bottom: 8px;
}

h3 {
  // @apply text-secondary;
  font-size: 1.5rem;
  margin-bottom: 4px;
}

h4 {
  // @apply text-secondary;
  font-size: 1.125rem;
  margin-bottom: 2px;
}

button {
  @apply bg-tertiary;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    @apply bg-secondary;
  }
}

input[type='text'] {
  @apply rounded-md text-black;
}

// Scroll bars
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.375rem;

}

::-webkit-scrollbar-thumb {
  @apply bg-tertiary;
  border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mix-list {
  padding: 0 12.5%;
}

.mixes-header {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;

    &>* {
      justify-content: space-between;
    }
  }

  &>* {
    @apply my-auto;
  }

  & span {
    display: flex;
    gap: 8px;

    & input {
      padding: 5px 10px;
    }
  }
}

.tracklist {
  // padding: 0 12.5%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.track {
  @apply bg-primary;
  padding: 8px 16px;
  border-radius: 1.125rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

ul {
  list-style-type: none;
  padding: 0;
}

.foldable {
  &>span {
    display: flex;
    justify-content: space-between;

    & svg {
      cursor: pointer;
      @apply my-auto;
    }
  }
}